import * as React from "react"
import { Container, Row, Col, Form  } from "react-bootstrap"
import {Link}  from "gatsby"

import Seo from "../../../../components/seo"
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import Valuation from "../../../../components/Valuation/ValuationForm/HomeVisitValuation"


const HomeVisitValuation = () => (
    <div className="wrapper home-visit-valuation-page">
    <Header/>
      <Seo title="Home Visit Valuation" />
      <Valuation />
      <Footer />
    </div>
  )
  
  export default HomeVisitValuation
  