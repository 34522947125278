import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { navigate, useMatch } from "@reach/router"
import axios from "axios";

import Select from "react-dropdown-select";
import parse from "html-react-parser"
import Img from 'gatsby-image';
import $ from "jquery"
import loadable from '@loadable/component'
import Img01 from "../../../images/avatar.png"
import ScrollAnimation from 'react-animate-on-scroll';
import "./ValuationForm.scss"
import TrustPilotWidget from "../../TrustPilotWidget"

import HomeVisitForm from "../../forms/home-visit-valuation"
import InstantForm from "../../forms/instant-valuation"
import HomeTrackResult from "./ValuationHomeTrackResult"

import { CONTACT_PAGE_URL, FIND_A_PROPERTY_PAGE_URL } from "../../common/site/constants"

import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
// markup
import GoogleReviewCount from "../../GoogleReviewCount/GoogleReviewCount"

const ValuationForm = (props) => {

    const [homevisitform, setHomeVisitForm] = useState(true);
    const [homevisitformresult, setHomeVisitFormResult] = useState(false);
    const [instantform, setInstantForm] = useState(false);
    const [instantformresult, setInstantFormResult] = useState(false);
    const [landing, setLanding] = useState(true);
    const [error, setError] = useState(false);
    const [errorin, setErrorIn] = useState(false);
    const [address, setAddress] = useState('');

    const [home_stno, setHomeStNo] = useState('');
    const [home_street, setHomeStreet] = useState('');
    const [home_district, setHomeDistrict] = useState('');
    const [home_post_town, setHomePostTown] = useState('');
    const [home_county, setHomeCounty] = useState('');
    const [home_postcode, setHomePostcode] = useState('');
    const [home_type, setHomeType] = useState('');

    const [bedroom, setBedroom] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [ptype, setPType] = useState('');
    const [Homeaddress, setHomeAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [type, setType] = useState('');

    function OpenLanding(e) {
        navigate(`/sell-your-property/property-valuation/`)
        if (localStorage) {
            localStorage.removeItem('instant-address')
            localStorage.removeItem('instant-postcode')
        }
    }


    function HomeVisit() {
        setInstantFormResult(false)
        setHomeVisitFormResult(true)
        setInstantForm(false)
        $('html, body').animate({
            scrollTop: $("#home-form").offset().top - 200
        }, 1000);
        if (localStorage) {
            localStorage.removeItem('instant-address')
            localStorage.removeItem('instant-postcode')
        }
    }

    useEffect(() => {
        if (localStorage) {
            if (localStorage.getItem('instant-address')) {
                // alert(localStorage.getItem('instant-address-full'))
                setHomeAddress(localStorage.getItem('instant-address'))
                setAddress(localStorage.getItem('instant-address-full'))
                setPostcode(localStorage.getItem('instant-postcode'))

                setHomeStNo(localStorage.getItem('instant-stno'))
                setHomeStreet(localStorage.getItem('instant-street'))
                setHomeDistrict(localStorage.getItem('instant-district'))
                setHomePostTown(localStorage.getItem('instant-post_town'))
                setHomeCounty(localStorage.getItem('instant-county'))
                setHomePostcode(localStorage.getItem('instant-postcode'))
                setType(localStorage.getItem('home-visit-type-val'))

                setHomeVisitForm(true)
            }
            else {
                navigate(`/sell-your-property/property-valuation/`)
            }
        }
        else {
            navigate(`/sell-your-property/property-valuation/`)
        }

    }, []);


    return (
        <React.Fragment>
            <section className="valuation-page home-visit-form-lan">
                <div className="page">
                    {homevisitform && (!homevisitformresult) &&
                        <div className="home-visit-form valuation-form" id="home-visit-form">
                            <Container>
                                <Row>
                                    <Col lg={10}>
                                        <div className="head">
                                            <span class="sm-text">Home visit valuation for</span>
                                            <h1>{address}</h1>
                                            <div className="back">
                                                Is this address incorrect? <span className="link" onClick={()=>OpenLanding()}>Re-enter your address</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <HomeVisitForm address={address} home_stno={home_stno} home_street={home_street} home_district={home_district} home_post_town={home_post_town} home_county={home_county} home_postcode={home_postcode} type={type} homevisit={HomeVisit} />

                                        <div className="connect-us">
                                            <div className="connect-wrapper">
                                                <div className="img">
                                                    <picture>
                                                        <img src={Img01} alt="Img" />
                                                    </picture>
                                                </div>
                                                <div className="img-details">
                                                    <h2>Speak to your local property experts today</h2>
                                                    <div className="contact">
                                                        <a href="tel:0800 854 499" className="call"><i className="icon-call"></i></a>
                                                        <a href="tel:0800 854 499" className="tel">0800 854 499</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }

                    {homevisitformresult &&
                        <div className="instant-form home-visit-success-results" id="home-form">
                            <Container>
                                <Row>
                                    <Col lg={10}>
                                        <div className="head">
                                            <span class="sm-text">Home visit valuation</span>
                                            <h1>Thank You</h1>
                                            <div className="back">Your request has been sent to our team and we will contact you shortly to arrange an appointment and explain the process in more detail.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8} className="main-banner">
                                        <div className="btn-wrap">
                                            <Link to={CONTACT_PAGE_URL.alias} class="btn btn-secondary">
                                                <span>For any enquiries</span>Contact Us
                                            </Link>
                                            <Link to={FIND_A_PROPERTY_PAGE_URL.alias} class="btn">
                                                <span>Buying or Renting?</span>Find a Property
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }
                </div>
            </section>
        </React.Fragment >
    )
}
export default ValuationForm